.App {
  margin: 20px;
}

.pagination {
  justify-content: center;
}

.clickableTable tbody tr {
  cursor: pointer;  
}

.group {
  padding: 20px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.react-datepicker-wrapper input {
  height: 38px;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
}


.topBar {
  height:20px;
  text-align: right;
}